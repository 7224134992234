import NextLink from 'next/link'

const customLink = ({ handleClick, openNewTab, className = '', children, ...rest }) => {
  return (
    <NextLink {...rest} passHref legacyBehavior>
      <a
        className={`ptm-custom-link ${className}`}
        target={openNewTab ? `_blank` : ''}
        onClick={
          handleClick
            ? e => {
                e.preventDefault()
                handleClick()
              }
            : undefined
        }
      >
        {children}
      </a>
    </NextLink>
  )
}

export default customLink
