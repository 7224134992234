import { MainMenus } from '@/components/layout/includes/menu.constant'
import aboutPayToMeCo from '~/public/assets/images/top-menu/company/about-paytomeco.svg'
import boardOfDirectors from '~/public/assets/images/top-menu/company/board-of-directors.svg'
import contactUs from '~/public/assets/images/top-menu/company/contact-us.svg'
import newsAndPress from '~/public/assets/images/top-menu/company/news-and-press.svg'
import partners from '~/public/assets/images/top-menu/company/partners.svg'

export const footerMenuData = [
  {
    id: 1,
    title: 'Products',
    children: MainMenus[0].children,
    items: [
      {
        name: 'Digital Invoice',
        slug: '/online-invoice-generator'
        // slug: '/digital-invoice'
      },
      {
        name: 'Text-To-Pay',
        slug: '/text-to-pay'
      },
      {
        name: 'Digital Checkbook',
        slug: '/digital-checkbook'
      },
      {
        name: 'Retails POS System',
        slug: '/retails-pos-system'
      },
      {
        name: 'Online Checkout Options',
        slug: '/online-checkout-options'
      },
      {
        name: 'Digital Invoice Generator',
        slug: '/invoice-generator'
      },
      {
        slug: '/debit-credit-card',
        name: 'Debit & Credit Card'
      }
    ]
  },
  {
    id: 2,
    title: 'Solutions Platforms',
    children: MainMenus[1].children,
    items: [
      {
        name: 'Merchant Services',
        slug: '/merchant-services'
      },
      // {
      //   name: 'Retail Payment Services',
      //   slug: '/retail-payment-services'
      // },
      {
        name: 'Accounts Receivables',
        slug: '/accounts-receivables'
      },
      {
        name: 'Accounts Payable',
        slug: '/accounts-payable'
      },
      {
        name: 'Expense Management',
        slug: '/expense-management'
      },
      {
        name: 'Global Money Movement',
        slug: '/global-money-movement'
      }
    ]
  },
  {
    id: 3,
    title: 'Merchant Services',
    children: MainMenus[2].children,
    items: [
      {
        name: 'PayFac (PayFac-as a-Service)',
        slug: '/payfac-as-a-service'
      },
      {
        name: 'PaaS (Payment-as-a-Service)',
        slug: '/payment-as-a-service'
      },
      {
        name: 'SaaS (Software-as-a-Service)',
        slug: '/software-as-a-service'
      }
    ]
  },
  {
    id: 4,
    title: 'Pricing',
    children: MainMenus[4].children,
    items: [
      {
        name: 'About us',
        slug: '/about-us'
      },
      {
        name: 'Contact Us',
        slug: '/contact-us'
      },
      {
        name: 'News & Press',
        slug: '/news'
      },
      {
        name: 'Board of Directors',
        slug: '/board-of-directors'
      },
      {
        name: 'Privacy Policy',
        slug: '/privacy-policy'
      },
      {
        name: 'Terms of Use',
        slug: '/terms-of-use'
      }
    ]
  },
  {
    id: 5,
    title: 'Company',
    children: [
      {
        id: 1,
        url: '/about-us',
        label: 'About PayToMe.co'
      },
      {
        id: 2,
        url: '/news',
        label: 'News & Press'
      },
      {
        id: 3,
        url: '/board-of-directors',
        label: 'Board of Directors'
      },
      {
        id: 4,
        url: '/partners',
        label: 'Partners'
      },
      {
        id: 5,
        url: '/contact-us',
        label: 'Contact Us'
      },
      {
        id: 6,
        label: 'Privacy Policy',
        url: '/privacy-policy'
      },
      {
        id: 7,
        label: 'Terms of Use',
        url: '/terms-of-use'
      }
    ]
  }
]
