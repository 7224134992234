import React from 'react'
import { KeyValuePairs } from '@/types/global'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import FooterLinkItem from '@/components/layout/includes/mobile-footer/FooterLinkItem'
import CrossCloseIcon from '@/components/icons/CrossCloseIcon'
import PlusBoldIcon from '@/components/icons/PlusBoldIcon'

interface FooterLinksProps {
  title?: string
  items: KeyValuePairs[]
  keyName?: string
  expanded: string | boolean
  setExpanded: (value: string | boolean) => void
}

export default function FooterLinksAccordion(props: FooterLinksProps) {
  const { title, items, keyName = 'panel1', expanded, setExpanded } = props
  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false)
  }

  return (
    <>
      <Accordion
        expanded={expanded === keyName}
        className={'m-0 min-h-10 bg-transparent !shadow-none'}
        onChange={handleChange(keyName)}
      >
        <AccordionSummary
          aria-controls={`${keyName}d-content`}
          id={`${keyName}d-header`}
          className={'min-h-10 !bg-transparent px-0 [&_.MuiAccordionSummary-content]:!m-0'}
          expandIcon={
            <div className={'mx-auto min-w-8'}>
              {expanded === keyName ? <CrossCloseIcon className={'mx-auto'} /> : <PlusBoldIcon className={'mx-auto'} />}
            </div>
          }
        >
          <div className='flex min-h-10 items-center'>
            <p className={'text-2xl font-semibold text-[#060F5C]'}>{title}</p>
          </div>
        </AccordionSummary>
        <AccordionDetails className={'!px-0 !pb-2 !pt-4'}>
          <FooterLinkItem items={items} />
        </AccordionDetails>
      </Accordion>
    </>
  )
}
