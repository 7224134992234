import * as React from 'react'
import { styled } from '@mui/material/styles'
import { FormControlLabel, Switch } from '@mui/material'

const StyledSwitch = styled(Switch)(({}) => ({
  width: 55,
  height: 28,
  padding: 0,

  '& .MuiSwitch-track': {
    borderRadius: '20px',

    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)'
    },
    '&:after': {
      content: '"OFF"',
      right: 5,
      color: '#FFF',
      fontSize: '12px',
      fontWeight: 700
    }
  },

  '& .MuiButtonBase-root': {
    padding: '3px 0 0 2px'
  },

  '& .MuiButtonBase-root.Mui-checked': {
    padding: '3px 0 0 11px',
    color: '#fff'
  },

  '& .MuiSwitch-switchBase+.MuiSwitch-track': {
    backgroundColor: '#f00',
    opacity: 1
  },

  '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
    opacity: 0.6,
    backgroundColor: '#00B050'
  },

  '& .Mui-disabled+.MuiSwitch-track': {
    backgroundColor: '#7f7f7f !important',
    opacity: '0.6 !important'
  },

  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 22,
    height: 22,
    margin: 0
  },

  '& .Mui-checked .MuiSwitch-thumb': {
    margin: '0 0 0 0'
  },

  '& .Mui-checked + .MuiSwitch-track': {
    '&:before': {
      left: 7,
      content: '"ON"',
      color: '#FFF',
      fontSize: '12px',
      fontWeight: 700
    },
    '&:after': {
      content: '""',
      right: 5,
      color: '#FFF',
      fontSize: '12px',
      fontWeight: 700
    }
  }
}))

const CustomSwitch = ({ label, name, setter, isChecked, handleChange, customStyles, ...rest }) => {
  const changeSetter = event => {
    if (setter) {
      setter(event.target.checked)
    }
  }

  return (
    <>
      <FormControlLabel
        sx={{ margin: 0, gap: '5px', alignItems: 'center', ...customStyles }}
        label={label || ''}
        control={
          <StyledSwitch
            checked={isChecked}
            onChange={e => (setter ? changeSetter(e) : handleChange ? handleChange(e, name) : e.preventDefault())}
            name={name}
          />
        }
        {...rest}
      />
    </>
  )
}

export default CustomSwitch
