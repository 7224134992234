import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import PtmLoadingButton from '@/components/buttons/PtmLoadingButton'
import Icon from '@/@core/components/icon'
import PropTypes from 'prop-types'
import CookieAccordion from '@/components/cookie-policy/CookieAccordion'

const CookiePolicy = ({ acceptCookie, preferences, changePreferences }) => {
  return (
    <>
      <Box>
        <Typography sx={{ mb: 2 }} variant={'h6'}>
          Your Privacy is Important to Us:
        </Typography>
        <Typography sx={{ mb: 2, display: { xs: 'none', md: 'block' } }}>
          Cookies are like tiny text files that are saved on your computer when you visit a website. We use these
          cookies to do many different things that make your experience on our website better. For example, they help us
          remember things like how you log into your account.
        </Typography>
        <Typography sx={{ mb: 2 }}>
          You have the power to decide how you want to deal with cookies. You can say "no" to certain types of cookies
          and decide if you want them stored on your computer when you're browsing our website. If you want, you can
          also get rid of any cookies that are already on your computer. But be aware that if you delete cookies, you
          might not be able to use all parts of our website.
        </Typography>
        <>
          <Box id={'manage-preferences'} sx={{ borderTop: '2px solid', mt: 6 }}>
            <Box sx={{ pt: 6, mb: 2 }}>
              <CookieAccordion preferences={preferences} changePreferences={changePreferences} />
            </Box>
            <Box
              sx={{
                mt: { xs: 4, md: 4 },
                display: 'flex',
                justifyContent: { xs: 'center', md: 'space-around' },
                alignItems: 'center',
                gap: { xs: '20px', md: '10px' },
                flexDirection: { xs: 'column', md: 'row' }
              }}
            >
              <PtmLoadingButton
                type={'submit'}
                bgColor={'#100aff'}
                onClick={() => acceptCookie('save-preferences')}
                sx={{
                  textTransform: 'none !important',
                  borderRadius: '6px',
                  height: '2.875rem',
                  fontSize: 14,
                  fontWeight: 600
                }}
                endIcon={
                  <span style={{ display: 'flex' }}>
                    <Icon icon='material-symbols:save' />
                  </span>
                }
              >
                Save Preferences
              </PtmLoadingButton>
            </Box>
          </Box>
        </>
      </Box>
    </>
  )
}

CookiePolicy.propTypes = {
  preferences: PropTypes.object.isRequired,
  acceptCookie: PropTypes.func.isRequired,
  changePreferences: PropTypes.func.isRequired
}

export default CookiePolicy
