import Header from '@/components/layout/Header'
import Footer from '@/components/layout/Footer'
import ScrollToTop from '@/custom-components/scroll/ScrollToTop'
import WindowWrapper from '@/@core/components/window-wrapper'
import CookiePolicy from '@/components/cookie-policy'
import CustomDialog from '@/custom-components/CustomDialog'
import Helpers from '@/helpers'
import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Icon from '@/@core/components/icon'
import Typography from '@mui/material/Typography'
import CustomLink from '@/custom-components/CustomLink'
import PtmLoadingButton from '@/components/buttons/PtmLoadingButton'

const Layout = ({ children }) => {
  const cookieAccepted = typeof window !== 'undefined' ? Helpers.getEncryptedCookie('_cp') : false
  const isCookieTriggeredBefore = cookieAccepted && Object.keys(cookieAccepted)?.length > 0

  const [preferences, setPreferences] = useState({
    functionality: true,
    tracking: true,
    advertising: true
  })

  const [openCookieModal, setOpenCookieModal] = useState(false)
  const [openCookieSettingsModal, setOpenCookieSettingsModal] = useState(false)

  //TODO: Don't delete this code. Need to enable later
  /*useEffect(() => {
    setOpenCookieModal(!isCookieTriggeredBefore)
  }, [isCookieTriggeredBefore])*/

  const acceptCookie = action => {
    if (action === 'save-preferences') {
      Helpers.setEncryptedCookie('_cp', preferences)
    } else {
      Helpers.setEncryptedCookie('_cp', {
        functionality: true,
        tracking: true,
        advertising: true
      })
    }
    setOpenCookieSettingsModal(false)
    setOpenCookieModal(false)
  }

  const changePreferences = (event, selectedStatus) => {
    const { name, checked: isChecked } = event.target
    if (name === selectedStatus) {
      setPreferences({ ...preferences, [name]: isChecked })
    }
  }

  return (
    <>
      <Header key={'header'} />
      <main className={'ptm-content-wr'}>{children}</main>
      <Footer />
      <WindowWrapper>
        <ScrollToTop />
      </WindowWrapper>
      {openCookieModal && (
        <>
          <Box
            sx={{
              zIndex: '99999',
              position: 'sticky',
              bottom: 0,
              background: '#fff',
              py: 6,
              display: 'flex',
              alignItems: { xs: 'flex-start', md: 'center' }
            }}
          >
            <Box className={'container'} sx={{}}>
              <Box
                sx={{
                  mt: { xs: 4, md: 4 },
                  display: 'flex',
                  justifyContent: { xs: 'center', md: 'space-between' },
                  alignItems: 'center',
                  gap: { xs: '20px', md: '10px' },
                  flexDirection: { xs: 'column', md: 'row' }
                }}
              >
                <Typography sx={{ maxWidth: '1000px', color: '#181059', fontSize: 14 }}>
                  By clicking “Accept All Cookies”, you agree to the storing of cookies on your device to enhance site
                  navigation, analyze site usage, and assist in our marketing efforts. For further details see our{' '}
                  <CustomLink href={'/cookie-policy'}>cookie policy</CustomLink>.
                </Typography>

                <Box
                  sx={{
                    // mt: { xs: 4, md: 4 },
                    display: 'flex',
                    justifyContent: { xs: 'center', md: 'space-around' },
                    alignItems: 'center',
                    gap: { xs: '20px', md: '30px' },
                    flexDirection: { xs: 'column', md: 'row' }
                  }}
                >
                  <PtmLoadingButton
                    type={'submit'}
                    bgColor={'#27ae60'}
                    onClick={acceptCookie}
                    sx={{
                      textTransform: 'none !important',
                      borderRadius: '100px',
                      height: '2.875rem',
                      fontSize: 14,
                      fontWeight: 600
                    }}
                    endIcon={
                      <span style={{ display: 'flex' }}>
                        <Icon icon='healthicons:i-documents-accepted' />
                      </span>
                    }
                  >
                    Accept all and visit site
                  </PtmLoadingButton>

                  <PtmLoadingButton
                    type={'submit'}
                    bgColor={'#100aff'}
                    onClick={() => setOpenCookieSettingsModal(true)}
                    sx={{
                      textTransform: 'none !important',
                      borderRadius: '100px',
                      height: '2.875rem',
                      fontSize: 14,
                      fontWeight: 600
                    }}
                    endIcon={
                      <span style={{ display: 'flex' }}>
                        <Icon icon='material-symbols:bookmark-manager' />
                      </span>
                    }
                  >
                    Manage My Preferences
                  </PtmLoadingButton>
                </Box>
              </Box>
            </Box>

            <Box sx={{ pr: { xs: 6, md: 6 }, pt: { xs: 4, md: 0 } }} onClick={() => setOpenCookieModal(false)}>
              <span style={{ display: 'flex', cursor: 'pointer' }}>
                <Icon icon='maki:cross' />
              </span>
            </Box>
          </Box>
        </>
      )}

      {openCookieSettingsModal && (
        <CustomDialog
          title={'Cookie Preferences'}
          showModal={openCookieSettingsModal}
          closeModal={() => setOpenCookieSettingsModal(false)}
          contentSx={{
            pb: { xs: '15px', sm: '30px' },
            px: { xs: '15px', sm: '50px' },
            pt: { xs: 0, sm: 0 }
          }}
        >
          <CookiePolicy preferences={preferences} acceptCookie={acceptCookie} changePreferences={changePreferences} />
        </CustomDialog>
      )}
    </>
  )
}

export default Layout
