import React from 'react'
import FooterLinksAccordion from '@/components/layout/includes/mobile-footer/FooterLinksAccordion'
import { footerMenuData } from '@/constants/footer/constant'
import FooterLinkItem from '@/components/layout/includes/mobile-footer/FooterLinkItem'
import FooterSocialMobile from '@/components/layout/includes/FooterSocial'
import { cn } from '@/lib/utils'

export default function FooterMenuLinks() {
  const [expanded, setExpanded] = React.useState<string | boolean>('panel-0')

  return (
    <>
      {footerMenuData?.length > 0 &&
        footerMenuData.map((menu, index) => (
          <div key={menu?.id} className={cn('col-span-1', { 'xl:flex xl:justify-end': ![0, 1].includes(index) })}>
            <div>
              <div className='hidden lg:block'>
                <FooterLinkItem title={menu?.title || ''} items={menu?.children || []} />
              </div>
              <div className='lg:hidden'>
                <FooterLinksAccordion
                  keyName={`panel-${index}`}
                  title={menu?.title || ''}
                  items={menu?.children || []}
                  expanded={expanded}
                  setExpanded={setExpanded}
                />
              </div>
            </div>
          </div>
        ))}
      <div className={'col-span-1 hidden items-end lg:flex xl:hidden'}>
        <FooterSocialMobile />
      </div>
    </>
  )
}
