import { Box, Fab, Slide, useScrollTrigger } from '@mui/material'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'

const ScrollToTop = () => {
  const trigger = useScrollTrigger({
    target: window,
    threshold: 100
  })

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  return (
    <Slide direction='up' in={trigger}>
      <Box
        onClick={handleClick}
        role='presentation'
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          transition: 'opacity 0.3s',
          opacity: trigger ? 1 : 0,
          '&:hover': {
            opacity: 1
          }
        }}
      >
        <Fab color='primary' size='small'>
          <ArrowUpwardIcon />
        </Fab>
      </Box>
    </Slide>
  )
}

export default ScrollToTop
