import React from 'react'
import { cn } from '@/lib/utils'

const LogoOrNameOnCard = ({ invoiceData }) => {
  const logoOrName = invoiceData?.logo

  // console.log({ logoOrName })

  const logoUrl = logoOrName?.logo || logoOrName?.public_link
  const no_image_available = !logoOrName?.type || (!logoUrl && !logoOrName?.company_name_1)

  return (
    <>
      <div
        className={cn('ptm-logo-on-card-wr', {
          circular: logoOrName?.radius === 'circular',
          '!min-w-[250px]': logoOrName?.type === 'name'
        })}
      >
        {logoOrName?.type === 'logo' && logoUrl && (
          <>
            <img
              className={cn('h-[44px]', logoOrName?.radius === 'circular' && 'w-[44px] rounded-full object-cover')}
              src={logoUrl}
              alt={'vendor logo'}
            />
          </>
        )}

        {logoOrName?.type === 'name' && (
          <div className={'flex flex-col items-center'}>
            <p className={'text-center text-sm font-semibold leading-tight text-blue-strong'}>
              {logoOrName?.company_name_1}
            </p>
            <p className={'text-center text-xs text-black'}>{logoOrName?.company_name_2}</p>
          </div>
        )}

        {no_image_available && (
          <>
            <img
              className={cn('h-[44px]', logoOrName?.radius === 'circular' && 'w-[44px] rounded-full object-cover')}
              src={'/assets/images/Image_not_available.png'}
              alt={'No Image Available'}
            />
          </>
        )}
      </div>
    </>
  )
}

export default LogoOrNameOnCard
