import React from 'react'
import Link from 'next/link'
import Image from 'next/image'
import iconList from '@/constants/IconList'
import parse from 'html-react-parser'
import { motion, stagger } from 'framer-motion'
import { cn } from '@/utils/tsx-helpers'

const staggerMenuItems = stagger(0.1, { startDelay: 0.15 })

export default function ParentMenu({ item, setIsOpen, activeTab, setActiveTab }) {
  const isActive = activeTab === item.id

  return (
    <>
      <li className={'parent-li border-b-[1.5px] border-[#F3F3F8] last:border-0'}>
        <div
          className={cn('flex cursor-pointer items-center justify-between bg-white py-2 text-base font-semibold', {
            'border-b-[1.5px] border-[#F3F3F8]': isActive
          })}
          onClick={() => setActiveTab(isActive ? null : item.id)}
        >
          <p
            className={cn('text-lg font-normal text-[#1443BD]', {
              'font-bold text-blue-clear': isActive
            })}
          >
            {item.name}
          </p>
          {item?.children?.length > 0 && (
            <motion.div
              className={'arrow'}
              transition={{ duration: 0.3 }}
              animate={{
                rotate: isActive ? 180 : 0
              }}
            >
              <ArrowDown stroke={isActive ? '#1678FB' : '#ABABAE'} />
            </motion.div>
          )}
        </div>
        {item?.children?.length > 0 && isActive && (
          <ul className={'m-0 flex flex-col items-start justify-start gap-4 px-0 pb-6 pt-4'}>
            {item?.children.map((child, index2) => (
              <motion.li
                key={index2}
                className={'group'}
                onClick={() => setIsOpen(false)}
                transition={{
                  duration: 0.3,
                  delay: isActive ? staggerMenuItems : 0
                }}
                animate={{
                  opacity: isActive ? 1 : 0,
                  scale: isActive ? 1 : 0.3,
                  filter: isActive ? 'blur(0px)' : 'blur(20px)'
                }}
              >
                <Link href={child.url} className={'w-full'}>
                  <div className={'flex h-[48px] items-center justify-start gap-5'}>
                    {!!child?.icon && (
                      <div className={'h-[48px] w-[48px] text-center'}>
                        <Image src={child.icon} width={48} height={48} alt={child.label} />
                      </div>
                    )}
                    <div className={'w-[calc(100%-68px)]'}>
                      <div className='flex h-[48px] items-center justify-start gap-1.5'>
                        <p className={'mb-0 text-base font-medium text-[#170F49] group-hover:text-[#4A3AFF]'}>
                          {child.label}
                        </p>
                        <span className={'mt-[-3px] hidden group-hover:inline'}>{iconList.rightArrow}</span>
                      </div>
                    </div>
                  </div>
                </Link>
              </motion.li>
            ))}
          </ul>
        )}
      </li>
    </>
  )
}

const ArrowDown = props => {
  return (
    <svg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.0811 1L5.54064 5.19254L1.00023 1'
        stroke='#ABABAE'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
        {...props}
      />
    </svg>
  )
}
