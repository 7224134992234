import { sendRequest } from './rootApi'
import ApiRoutes from './ApiRoutes'

/* GET */
export const fetchAccountInfo = (payload = {}, queryParams = null) => {
  return sendRequest('GET', ApiRoutes.myAccount, payload, queryParams)
}

export const getAllCustomerInvoices = (payload = {}, queryParams = null) => {
  return sendRequest('GET', ApiRoutes.fetchCustomerInvoices, payload, queryParams)
}

export const getCountries = (payload = {}, queryParams = null) => {
  return sendRequest('GET', ApiRoutes.countries, payload, queryParams)
}

export const getStatesByCountryId = (payload = {}, queryParams = null) => {
  return sendRequest('GET', ApiRoutes.states, payload, queryParams)
}

export const getInvoiceForPay = (payload = {}, queryParams = null) => {
  return sendRequest('GET', ApiRoutes.payInvoice, payload, queryParams, { 'Referer-Place': 'public' })
}

export const getPublicInvoiceForPay = (payload = {}, queryParams = null) => {
  return sendRequest('GET', ApiRoutes.payPublicInvoice, payload, queryParams)
}

/* POST */
export const loginToTheApp = (payload = {}, queryParams = null) => {
  return sendRequest('POST', ApiRoutes.login, payload, queryParams)
}
export const verifyOTPLoginToTheApp = (payload = {}, queryParams = null) => {
  return sendRequest('PATCH', ApiRoutes.otpVerify, payload, queryParams)
}

export const logoutFromTheApp = (payload = {}, queryParams = null) => {
  return sendRequest('POST', ApiRoutes.logout, payload, queryParams)
}

export const registerToApp = (payload = {}, queryParams = null) => {
  return sendRequest('POST', ApiRoutes.register, payload, queryParams)
}

export const forgotPassword = (payload = {}, queryParams = null) => {
  return sendRequest('POST', ApiRoutes.forgotPass, payload, queryParams)
}

export const resetPassword = (payload = {}, queryParams = null) => {
  return sendRequest('POST', ApiRoutes.resetPass, payload, queryParams)
}

export const processPayment = (payload = {}, queryParams = null) => {
  return sendRequest('POST', ApiRoutes.makeInvoice, payload, queryParams)
}

/* DELETE */

/* PUT */
export const rejectInvoice = (payload = {}, queryParams = null) => {
  return sendRequest('PUT', ApiRoutes.invoiceRejection, payload, queryParams)
}
