import { useRouter } from 'next/router'
import footerStyles from '~/styles/scss/footer/Footer.module.scss'
import Link from 'next/link'
import { cn } from '@/lib/utils'

const FooterActiveLink = ({ href, openNewTab, className = '', children, ...rest }) => {
  const router = useRouter()

  const pathname = router.asPath

  return (
    <Link {...rest} href={href} legacyBehavior>
      <a
        href={href}
        target={openNewTab ? `_blank` : ''}
        // className={`ptm-custom-link ${className} ` + (pathname === href ? footerStyles['active'] : '')}
        className={cn(`ptm-custom-link`, className, pathname === href ? footerStyles['active'] : '')}
      >
        {children}
      </a>
    </Link>
  )
}

export default FooterActiveLink
