import React from 'react'
import footerStyles from '~/styles/scss/footer/Footer.module.scss'
import Image from 'next/image'
import CustomLink from '@/custom-components/CustomLink'
import IconLists from '@/helpers/IconLists'
// import PTMLogo from '~/public/assets/images/logos/paytome.svg'
import paytomeFooter from '~/public/assets/svg/paytome-footer.svg'

const FooterSocial = () => {
  // const { desktopUp, desktopMini } = useResponsive()

  return (
    <>
      <div className='pb-8 pt-6 md:pb-12 lg:pb-4 xl:pb-17 xl:pt-0'>
        <div
          className={
            'grid grid-cols-1 gap-[34px] md:grid-cols-3 md:gap-0 lg:grid-cols-1 lg:gap-9 xl:grid-cols-6 xl:gap-0'
          }
        >
          <div className={'col-span-1 flex items-center justify-start md:col-span-2 lg:col-span-1 xl:col-span-5'}>
            <div
              className={
                'flex flex-col items-start justify-start gap-6 lg:gap-3.5 xl:flex-row xl:items-end xl:gap-[52px]'
              }
            >
              <CustomLink href={'/'}>
                <div className={'aspect-h-[60] aspect-w-[238] w-[238px] overflow-hidden xl:w-[284px]'}>
                  <Image
                    src={paytomeFooter}
                    alt={'PayToMe.co'}
                    className={'h-full w-full object-cover object-center'}
                    fill={true}
                    // width={desktopUp ? 284 : 278}
                    // height={desktopUp ? 69 : 69}
                  />
                </div>
              </CustomLink>
              <p className={'text-xl font-semibold text-[#252525]'}>PayToMe.co ®, 2024</p>
            </div>
          </div>
          <div
            className={
              'col-span-1 flex items-center justify-center md:items-end lg:items-center lg:justify-center xl:items-end'
            }
          >
            <div
              className={
                'flex w-full items-center justify-start gap-4 md:justify-center lg:justify-start xl:justify-center'
              }
            >
              <CustomLink
                openNewTab
                href={`https://www.linkedin.com/company/paytome`}
                className={footerStyles['button_style']}
              >
                {IconLists.linkedinIcon}
              </CustomLink>
              {/*<CustomLink href={`#`} className={footerStyles['button_style']}>
                {IconLists.twitterIcon}
              </CustomLink>*/}
              <CustomLink
                openNewTab
                href={`https://www.youtube.com/@paytome-co`}
                className={footerStyles['button_style']}
              >
                {IconLists.youtubeIcon}
              </CustomLink>
              {/*<CustomLink href={`#`} className={footerStyles['button_style']}>
                {IconLists.instagramIcon}
              </CustomLink>
              <CustomLink href={`#`} className={footerStyles['button_style']}>
                {IconLists.facebookIcon}
              </CustomLink>*/}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FooterSocial
