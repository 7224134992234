import { server } from '@/configs'

const ApiRoutes = {
  /* GET */
  fetchCustomerInvoices: `${server}/invoices`,
  testUsers: 'https://jsonplaceholder.typicode.com/users/',
  payInvoice: `${server}/i`,
  payPublicInvoice: `${server}/p`,
  countries: `${server}/countries`,
  states: `${server}/states`,
  getPDF: `${server}/states`,
  plans: `${server}/plans`,
  planPrices: `${server}/plan-prices`,

  /* POST */
  myAccount: `${server}/profile/my-account`,
  register: `${server}/auth/register`,
  login: `${server}/auth/login`,
  otpVerify: `${server}/login-otp-verify`,
  logout: `${server}/auth/logout`,
  forgotPass: `${server}/auth/forgot-password`,
  makeInvoice: `${server}/i/`,
  resetPass: `${server}/auth/reset-password`,
  contact: `${server}/contact`,

  /* PUT */
  invoiceRejection: `${server}/i/reject/`

  /* DELETE */
}

export default ApiRoutes
