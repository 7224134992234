import React, { forwardRef } from 'react'

import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import Fade from '@mui/material/Fade'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'

// ** Icon Imports
import IconLists from '../helpers/IconLists'
import PropTypes from 'prop-types'
import LogoOrNameOnCard from '@/components/processPayment/includes/LogoOrNameOnCard'
import { cn } from '@/lib/utils'

const Transition = forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />
})

const CustomDialog = props => {
  const {
    title = '',
    titleWrapperClass = '',
    children,
    showModal,
    closeModal,
    invoiceData,
    wantToShowLogo = false,
    submitable = false,
    ...rest
  } = props

  return (
    <Card>
      <Dialog
        fullWidth
        open={showModal}
        maxWidth='sm'
        scroll='body'
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            closeModal(false)
          } else {
            event.preventDefault()
          }
        }}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            overflowY: 'unset'
          }
        }}
        {...rest}
      >
        <div className={'relative'}>
          {wantToShowLogo ? (
            <>
              <LogoOrNameOnCard invoiceData={invoiceData} />
            </>
          ) : null}

          {title ? (
            <div className={'relative'}>
              <div
                className={cn(
                  `mb-4 border-b-2 border-[#CBD6E1] pb-2.5 pt-4 text-center md:mb-[22px]`,
                  titleWrapperClass
                )}
              >
                <h2 className={'m-0 text-xl font-bold capitalize text-[#15191F] md:text-2xl'}>{title}</h2>
                <div className={'absolute right-4 top-0 flex h-[62px] items-center'}>
                  <IconButton
                    size='small'
                    onClick={() => closeModal(false)}
                    sx={{
                      padding: 0,
                      [' svg']: {
                        width: '24px',
                        height: '24px'
                      }
                    }}
                  >
                    {IconLists.closeIcon3}
                  </IconButton>
                </div>
              </div>
            </div>
          ) : null}

          <DialogContent
            sx={{
              pb: { xs: title ? '15px' : 8, sm: title ? 8 : 10 },
              px: { xs: title ? '15px' : 8, sm: title ? 8 : 10 },
              pt: { xs: title ? 0 : 8, sm: title ? 2 : 10 },
              ...(props?.contentSx || {}),
              position: 'relative'
            }}
          >
            {!title ? (
              <IconButton
                size='small'
                onClick={() => closeModal(false)}
                sx={{ position: 'absolute', right: '5px', top: '5px' }}
              >
                {IconLists.closeIcon3}
              </IconButton>
            ) : null}

            {children}
          </DialogContent>
        </div>
        {submitable ? (
          <DialogActions sx={{ pb: { xs: 8, sm: 12.5 }, justifyContent: 'center' }}>
            <Button variant='contained' sx={{ mr: 1 }} onClick={() => closeModal(false)}>
              Submit
            </Button>
            <Button variant='outlined' color='secondary' onClick={() => closeModal(false)}>
              Cancel
            </Button>
          </DialogActions>
        ) : null}
      </Dialog>
    </Card>
  )
}

CustomDialog.propTypes = {
  title: PropTypes.any.isRequired,
  children: PropTypes.any,
  showModal: PropTypes.bool.isRequired,
  wantToShowLogo: PropTypes.bool,
  submitable: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  titleWrapperClass: PropTypes.any,
  sx: PropTypes.object,
  contentSx: PropTypes.object
}
export default CustomDialog
