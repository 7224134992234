import React from 'react'
import FooterActiveLink from '@/components/layout/includes/FooterActiveLink'
import { KeyValuePairs } from '@/types/global'

interface FooterLinksProps {
  title?: string
  items: KeyValuePairs[]
}

export default function FooterLinkItem({ title, items }: FooterLinksProps) {
  return (
    <>
      <div>
        {!!title && <h3 className={'lg:!mb-6 lg:!text-[26px] xl:!mb-2.5 xl:!text-[24px]'}>{title}</h3>}
        <ul className={'space-y-6 lg:space-y-3'}>
          {items?.length > 0 &&
            items?.map((item, idx) => (
              <li key={idx}>
                <FooterActiveLink
                  href={item?.url}
                  openNewTab={undefined}
                  className={'!p-0 !text-xl lg:!text-lg xs:block'}
                >
                  {item?.label}
                </FooterActiveLink>
              </li>
            ))}
        </ul>
      </div>
    </>
  )
}
