import * as React from 'react'
import { styled } from '@mui/material/styles'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import CustomSwitch from '@/custom-components/switches/CustomSwitch'
import Box from '@mui/material/Box'
import { IconButton } from '@mui/material'

const Accordion = styled(props => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&:before': {
    display: 'none'
  }
}))

const AccordionSummary = styled(props => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    marginRight: theme.spacing(2),
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(2)
  }
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)'
}))

export default function CookieAccordion({ changePreferences, preferences }) {
  const [expanded, setExpanded] = React.useState('panel1')

  const handleChange = panel => {
    setExpanded(expanded === panel ? false : panel)
  }

  return (
    <>
      <Accordion expanded={expanded === 'panel1'}>
        <AccordionSummary
          expandIcon={
            <IconButton
              onClick={() => handleChange('panel1')}
              aria-expanded={expanded === 'panel1'}
              aria-label='show more'
            >
              <ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />
            </IconButton>
          }
          aria-controls='panel1d-content'
          id='panel1d-header'
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '10px'
            }}
          >
            <Typography sx={{}} onClick={() => handleChange('panel1')}>
              Strictly Necessary Cookies:
            </Typography>

            <Typography
              sx={{ color: '#100aff', minWidth: '80px', fontSize: { xs: 12, md: 14 }, fontWeight: '700 !important' }}
            >
              Always Active
            </Typography>
            {/*<CustomSwitch name={'performance'} isChecked={true} />*/}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ fontSize: 14, py: 2 }}>
            These cookies are like the building blocks of our website. They're super important because they let us offer
            you different services on our website. They're also what allow you to use specific features. Without these
            cookies, some things on our website just won't work.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'}>
        <AccordionSummary
          expandIcon={
            <IconButton
              onClick={() => handleChange('panel2')}
              aria-expanded={expanded === 'panel2'}
              aria-label='show more'
            >
              <ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />
            </IconButton>
          }
          aria-controls='panel2d-content'
          id='panel2d-header'
        >
          <Box
            sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '10px' }}
          >
            <Typography sx={{}} onClick={() => handleChange('panel2')}>
              Functionality Cookies:
            </Typography>

            <CustomSwitch
              name={'functionality'}
              isChecked={preferences.functionality}
              handleChange={changePreferences}
            />
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ fontSize: 14, py: 2 }}>
            These cookies are like the helpers that make your time on our website more personal and enjoyable. They
            remember choices you make, such as your preferred language or your login details. Think of them as your
            virtual assistants for a smoother experience.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'}>
        <AccordionSummary
          expandIcon={
            <IconButton
              onClick={() => handleChange('panel3')}
              aria-expanded={expanded === 'panel3'}
              aria-label='show more'
            >
              <ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />
            </IconButton>
          }
          aria-controls='panel3d-content'
          id='panel3d-header'
        >
          <Box
            sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '10px' }}
          >
            <Typography sx={{}} onClick={() => handleChange('panel3')}>
              Tracking Cookies:
            </Typography>

            <CustomSwitch name={'tracking'} isChecked={preferences.tracking} handleChange={changePreferences} />
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ fontSize: 14, py: 2 }}>
            Tracking cookies are like maps for our website. They collect information about how people are using our
            website and help us understand things like how long you spend here and which pages you visit the most. This
            knowledge is valuable because it guides us in making our website even better for you. But don't worry, these
            cookies don't know who you are personally.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'}>
        <AccordionSummary
          expandIcon={
            <IconButton
              onClick={() => handleChange('panel4')}
              aria-expanded={expanded === 'panel4'}
              aria-label='show more'
            >
              <ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />
            </IconButton>
          }
          aria-controls='panel4d-content'
          id='panel4d-header'
        >
          <Box
            sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '10px' }}
          >
            <Typography sx={{}} onClick={() => handleChange('panel4')}>
              Targeting and Advertising Cookies:
            </Typography>

            <CustomSwitch name={'advertising'} isChecked={preferences.advertising} handleChange={changePreferences} />
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ fontSize: 14, py: 2 }}>
            Imagine these cookies as your personal shoppers. They help us show you ads that you're more likely to find
            interesting. They do this by looking at what you've been doing on our website and on other websites too.
            Even if you decide not to use these cookies, you'll still see ads, but they might not be as tailored to your
            interests.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </>
  )
}
