export default function CrossCloseIcon(props: any) {
  return (
    <svg width={16} height={16} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M10.492 7.991l4.983-4.989c.394-.394.59-1.05.46-1.706C15.737.705 15.278.18 14.687.048c-.59-.131-1.245 0-1.704.46L8 5.497 3.016.507C2.557.049 1.902-.082 1.311.049.721.245.197.705.066 1.296c-.132.59 0 1.247.459 1.706l4.983 4.99L.525 12.98A1.795 1.795 0 000 14.228 1.794 1.794 0 001.77 16c.46 0 .919-.197 1.246-.525L8 10.485l4.984 4.99c.328.328.787.525 1.245.525A1.79 1.79 0 0016 14.228c0-.46-.197-.92-.525-1.248l-4.983-4.989z'
        fill='#060F5C'
      />
    </svg>
  )
}
