import { MenuItem, SxProps } from '@mui/material'
import { Settings } from '@/constants/Settings'
import { clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'
import React from 'react'
import { KeyValuePairs } from '@/types/global'
import { AnyObjectSchema } from 'yup'
import { CountryProps, StateProps } from '@/types/Types'

const currentYear = new Date().getFullYear()
const yearRange = Array.from({ length: 11 }, (_, i) => currentYear + i)

const renderExpiredYears = yearRange.map(eachYear => {
  return (
    <MenuItem key={eachYear} value={eachYear}>
      {eachYear}
    </MenuItem>
  )
})

const renderExpiredMonths = Settings.months.map(eachMonth => {
  return (
    <MenuItem key={eachMonth.name} value={eachMonth.value}>
      {eachMonth.name}
    </MenuItem>
  )
})

interface LocalImageProps {
  src: string
  alt?: string
  customStyles?: React.CSSProperties
  bodySx?: SxProps
}

export const genLocalImage = ({ src, alt = '', customStyles = {}, bodySx = {} }: LocalImageProps) => {
  return { src, alt, customStyles, bodySx }
}

export interface ContentProps {
  html: string
  sx?: SxProps
}

export const genContent = ({ html, sx = {} }: ContentProps) => {
  return { html, sx }
}

export interface ButtonProps {
  html: string
  url: string
  sx?: SxProps
}

export const genButton = ({ html, url, sx = {} }: ButtonProps) => {
  return { html, url, sx }
}

export interface ImageProps {
  path: string
  sx?: SxProps
}

export const genImage = ({ path, sx = {} }: ImageProps) => {
  return { path, sx }
}

export function cn(...inputs: any[]) {
  return twMerge(clsx(inputs))
}

export const setFormValues = (names: KeyValuePairs, setValue: (name: string, value: any) => void) => {
  if (Object.entries(names).length > 0) {
    for (const name in names) {
      setValue(name, names[name])
    }
  }
}

const TsxHelpers = {
  renderExpiredYears,
  renderExpiredMonths
}

export default TsxHelpers

export const yupSchemaValidate = async (schema: AnyObjectSchema, payloads: KeyValuePairs) => {
  return await schema
    .validate({ ...payloads }, { abortEarly: false })
    .then(() => {
      return {}
    })
    .catch(error => {
      const errors = {} as KeyValuePairs
      error?.inner?.forEach((error: KeyValuePairs) => {
        errors[error.path] = [error.message]
      })

      return errors
    })
}

export function formDataToObject(formData: FormData) {
  const object: { [key: string]: any } = {}
  formData.forEach((value, key) => {
    if (object.hasOwnProperty(key)) {
      if (Array.isArray(object[key])) {
        object[key].push(value)
      } else {
        object[key] = [object[key], value]
      }
    } else {
      object[key] = value
    }
  })

  return object
}

interface AddressProps {
  city?: string
  state?: StateProps
  zip_code?: string
  country?: CountryProps
}

export function formatAddress({ city, state, zip_code, country }: AddressProps): string {
  /*console.table({
        city,
        state,
        zip_code,
        country
      })*/

  const address = []
  const stateName = state?.s_code ? state.s_code : state?.state || state?.state_name || ''

  if (city) address.push(city)
  if (stateName) address.push(stateName)
  if (zip_code) address.push(zip_code)
  if (country?.iso2) address.push(country.iso2.toUpperCase())

  return address.join(', ')
}

type UploadAttachmentErrorsType = {
  'too-many-files': string
  'file-too-large': string
  'file-invalid-type': string
}

export const UploadAttachmentErrors = (
  maxFiles = 5,
  maxFileSizeMB = 5,
  accepted = ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'csv', 'ppt', 'pptx']
): UploadAttachmentErrorsType => {
  return {
    'too-many-files': `Oops! You can't upload more than ${maxFiles} files.`,
    'file-too-large': `Oops! Your file size is more than ${maxFileSizeMB}MB.`,
    'file-invalid-type': `File type must be ${accepted.join(', ')}`
  }
}

/*export const addProtocolToUrl = (urlString: string) => {
  let validUrl = urlString
  if (!/^https?:\/\//i.test(urlString)) {
    validUrl = 'https://' + urlString
  }

  // const prefix = 'http://'
  // if (urlString.substr(0, prefix.length) !== prefix) {
  //   urlString = prefix + urlString
  // }

  return validUrl
}*/

export const addProtocolToUrl = (urlString: string) => {
  let validUrl = urlString?.trim()?.replace('http//', '')

  if (!/^https?:\/\//i.test(urlString)) {
    validUrl = 'https://' + urlString
  }

  return validUrl
}

/*
 * note: we can test belows method for url prepend http
 * Ref: https://www.npmjs.com/package/prepend-http?activeTab=code
 * */
export const prependHttp = (url: string, { https = true } = {}) => {
  url = url.trim()
  if (/^\.*\/|^(?!localhost)\w+?:/.test(url)) {
    return url
  }
  return url.replace(/^(?!(?:\w+?:)?\/\/)/, https ? 'https://' : 'http://')
}
