const AppName = 'PayToMe'

const months = [
  {
    name: 'JAN',
    value: '01'
  },
  {
    name: 'FEB',
    value: '02'
  },
  {
    name: 'MAR',
    value: '03'
  },
  {
    name: 'APR',
    value: '04'
  },
  {
    name: 'MAY',
    value: '05'
  },
  {
    name: 'JUN',
    value: '06'
  },
  {
    name: 'JUL',
    value: '07'
  },
  {
    name: 'AUG',
    value: '08'
  },
  {
    name: 'SEP',
    value: '09'
  },
  {
    name: 'OCT',
    value: '10'
  },
  {
    name: 'NOV',
    value: '11'
  },
  {
    name: 'DEC',
    value: '12'
  }
]

const years = [
  {
    name: '2023',
    value: '2023'
  },
  {
    name: '2024',
    value: '2024'
  },
  {
    name: '2025',
    value: '2025'
  },
  {
    name: '2026',
    value: '2026'
  },
  {
    name: '2027',
    value: '2027'
  },
  {
    name: '2028',
    value: '2028'
  },
  {
    name: '2029',
    value: '2029'
  },
  {
    name: '2030',
    value: '2030'
  },
  {
    name: '2031',
    value: '2031'
  },
  {
    name: '2032',
    value: '2032'
  },
  {
    name: '2033',
    value: '2033'
  }
]

export const Settings = {
  AppName,
  months,
  years
}
